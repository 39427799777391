import React, {ReactNode, ReactNodeArray} from "react";
import css from './styles.module.scss';
import {combineClassnames} from "../../utils/combineClassnames";

export interface UnsubscribeLayoutProps {
    header: ReactNode | ReactNodeArray;
    paragraphs: ReactNode | ReactNodeArray;
    alignContent: 'left' | 'center';
    children?: ReactNode | ReactNodeArray;
    sectionClassName?: string;
    className?: string;
}

/**
 * Layout component to use for the unsubscribe pages
 * @param alignContent
 * @param props
 * @constructor
 */
const UnsubscribeLayout = ({alignContent = 'left', ...props}: UnsubscribeLayoutProps) => {
    const alignContentClass = alignContent === 'center' ? css.center_align : css.left_align;
    return (
        <main className={combineClassnames(
            css.main,
            alignContentClass,
            props.className
        )}>
            <header className={css.header}>{props.header}</header>
            <section className={combineClassnames(css.section, props.sectionClassName)}>
                {props.paragraphs}
                {props.children && <>{props.children}</>}
            </section>
        </main>
    );
};

export default UnsubscribeLayout;
