import * as React from 'react';
import ImmediateDownloadNow from './pages/ImmediateDownloadNow';
import LandingPage1 from './pages/landingPages/landingPage1';
import LandingPage2 from './pages/landingPages/landingPage2';
import LandingPage4 from './pages/landingPages/landingPage4';
import RegistrationPage from './pages/RegistrationPage';
import CheckYourEmailPage from './pages/CheckYourEmailPage';
import {Route, Router, Switch, Redirect} from 'react-router-dom';
import {ConfigService} from './services/ConfigService';
import {CustomerRegistrationStore} from './stores/CustomerRegistrationStore';
import history from './services/history';
import {AnimatePresence} from 'framer-motion';
import LandingPage3 from './pages/landingPages/landingPage3';
import SalesLandingPage from './pages/SalesLandingPage';
import UnsubscribePage from "./pages/UnsubscribePage/unsubscribePage";
import {IUnsubscribeStore} from "./stores/UnsubscribeStore";
import UnsubscribeSuccessPage from "./pages/UnsubscribePage/UnsubscribeSuccessPage";
import UnsubscribeFailedPage from "./pages/UnsubscribePage/UnsubscribeFailedPage";

interface IProps {
    store: CustomerRegistrationStore;
    unsubscribeStore: IUnsubscribeStore;
}

export enum ScreenRoutes {
    LandingPage1 = '/landing-1',
    LandingPage2 = '/landing-2',
    LandingPage3 = '/landing-3',
    LandingPage4 = '/landing-4',
    Promo = 'promo',
    AppDownload = '/app-download',
    Unsubscribe = '/unsubscribe'
}

function ActivePage(props: IProps) {

    const configs = ConfigService.getInstance().values;
    const constants = configs.constants;

    const getDefaultLandingPage = {
        ['LandingPage1']: () => <LandingPage1 customerRegistrationStore={props.store}/>,
        ['LandingPage2']: () => <LandingPage2 customerRegistrationStore={props.store}/>,
        ['LandingPage3']: () => <LandingPage3 customerRegistrationStore={props.store}/>,
        ['LandingPage4']: () => <LandingPage4 customerRegistrationStore={props.store}/>,
        ['RegistrationPage']: () => <RegistrationPage customerRegistrationStore={props.store}/>
    }

    const exclusiveLandingPagesMap = {
        ['demo-landing']: () => <SalesLandingPage/>
    }

    const exclusiveLandingPages = () => {
        const features = configs.features;
        if (!features.exclusiveLandingPages?.length) {
            return null;
        }
        return features.exclusiveLandingPages.map(pageName => <Route
            path={`/${pageName}`}>{exclusiveLandingPagesMap[pageName]}</Route>);
    }

    const unsubscribeDynamicRoute = (
        <>
            <Route exact
                   path={`${ScreenRoutes.Unsubscribe}/:id/success`}>
                <UnsubscribeSuccessPage store={props.unsubscribeStore}/>
            </Route>
            <Route path={`${ScreenRoutes.Unsubscribe}/:id/failed`}
                   exact
                   component={UnsubscribeFailedPage}
            />
            <Route path={`${ScreenRoutes.Unsubscribe}/:id`}
                   exact
                   render={(params: { match?: { params?: { id?: string } } }) =>
                       <UnsubscribePage key="dynamicUnsubscribe"
                                        store={props.unsubscribeStore}
                                        pathId={params?.match?.params?.id}/>}/>
            <Route path={`${ScreenRoutes.Unsubscribe}/`}
                   exact
                   component={UnsubscribeFailedPage}
            />
        </>
    );

    const features = configs.features;
    if (features.enableOnlyUnsubscribe) {
        // unsure why the routes break when extracted to a function
        return (
            <div className='page-container'>
                <AnimatePresence>
                    <Router history={history}>
                        <Route render={({location}) => (
                            <Switch location={location}
                                    key={location.pathname}>
                                <Route exact path="/">
                                    <Redirect to={ScreenRoutes.Unsubscribe}/>
                                </Route>
                                {unsubscribeDynamicRoute}
                            </Switch>
                        )}/>
                    </Router>
                </AnimatePresence>
            </div>
        );
    }

    return (
        <div className='page-container'>
            <AnimatePresence>
                <Router history={history}>
                    <Route render={({location}) => (
                        <Switch location={location}
                                key={location.pathname}>
                            <Route path='/landing-1'>
                                <LandingPage1 customerRegistrationStore={props.store}/>
                            </Route>
                            <Route path='/landing-2'>
                                <LandingPage2 customerRegistrationStore={props.store}/>
                            </Route>
                            <Route path='/landing-3'>
                                <LandingPage3 customerRegistrationStore={props.store}/>
                            </Route>
                            <Route path='/landing-4'>
                                <LandingPage4 customerRegistrationStore={props.store}/>
                            </Route>
                            <Route path='/landing-5'>
                                <LandingPage2 customerRegistrationStore={props.store}
                                              showDate={true}
                                              showTime={true}/>
                            </Route>
                            <Route path='/check-your-email'>
                                <CheckYourEmailPage customerRegistrationStore={props.store}/>
                            </Route>
                            <Route path={ScreenRoutes.AppDownload}>
                                <ImmediateDownloadNow customerRegistrationStore={props.store}/>
                            </Route>
                            {exclusiveLandingPages()}
                            <Route path='/promo'>
                                <LandingPage4 customerRegistrationStore={props.store}/>
                            </Route>
                            <Route exact path="/">
                                {getDefaultLandingPage[constants.DEFAULT_LANDING_PAGE]}
                            </Route>
                            {unsubscribeDynamicRoute}
                        </Switch>
                    )}
                    />
                </Router>
            </AnimatePresence>
        </div>
    )
}

export default ActivePage;
