import {IEmailService} from "../services/EmailService";
import {action, observable} from "mobx";
import {isIOS} from "react-device-detect";
import {ConfigService} from "../services/ConfigService";

export type UnsubscribeRequestStatus = 'idle' | 'loading' | 'success' | 'error';

export type UserType = 'WORKER' | 'CUSTOMER' | 'EMPLOYEE';

export interface IUnsubscribeStore {
    readonly requestStatus: UnsubscribeRequestStatus;
    readonly notificationId: string;
    readonly appStoreLink: string;
    readonly desktopLink: string;
    readonly email: string;
    readonly userType: UserType;

    getNotificationIdFromParams(urlParams: URLSearchParams): string;

    setNotificationId(id: string): void;

    sendUnsubscribeRequest(): Promise<UnsubscribeRequestStatus>;
}

const NOTIFICATION_ID_QUERY_KEY = 'id';
const EMAIL_QUERY_KEY = 'email';
const USER_TYPE_QUERY_KEY = 'userType';

type URLQueryParameters = {
    [NOTIFICATION_ID_QUERY_KEY]?: string;
    [EMAIL_QUERY_KEY]: string;
    [USER_TYPE_QUERY_KEY]: string;
};

export class UnsubscribeStore implements IUnsubscribeStore {
    notificationId: string | null;
    appStoreLink: string;
    desktopLink: string;
    email: string | null;
    userType: UserType | null;

    @observable requestStatus: UnsubscribeRequestStatus = 'idle';

    constructor(
        private emailService: IEmailService,
        // window.location.search
        readonly queryString: string
    ) {
        const urlParams = new URLSearchParams(queryString);

        this.notificationId = this.getNotificationIdFromParams(urlParams);
        this.userType = this.getParam<UserType>(urlParams, USER_TYPE_QUERY_KEY);
        this.email = this.getParam(urlParams, EMAIL_QUERY_KEY);
        this.appStoreLink = this.determineAppStoreLink();
        this.desktopLink = this.getDesktopLink();
    }

    getNotificationIdFromParams(urlParams: URLSearchParams): string {
        return this.getParam(urlParams, NOTIFICATION_ID_QUERY_KEY, '');
    }

    getParam<T = string>(urlParams: URLSearchParams, key: keyof URLQueryParameters, fallback?: T): T | null {
        return urlParams.get(key) as T ?? fallback;
    }

    setNotificationId(id: string) {
        this.notificationId = id;
    }

    @action.bound
    async sendUnsubscribeRequest(): Promise<UnsubscribeRequestStatus> {
        const notificationId = this.notificationId;
        if (!notificationId) {
            this.requestStatus = 'error';
            console.error('Cannot unsubscribe without a notification id');
            return;
        }

        console.debug('Requesting unsubscription with id', notificationId);

        this.requestStatus = "loading";

        const succeeded = await this.emailService.requestUnsubscribe(notificationId);
        if (!succeeded) {
            console.debug('Unsubscription failed', notificationId);
            this.requestStatus = 'error';
            return this.requestStatus;
        }

        console.debug('Unsubscription succeeded', notificationId);

        this.requestStatus = "success";

        return this.requestStatus;
    }

    getDesktopLink(): string {
        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;
        const { CUSTOMER_DESKTOP_LINK, WORKER_DESKTOP_LINK } = constants;
        const userType = this.userType;

        if (userType !== 'WORKER') {
            return CUSTOMER_DESKTOP_LINK || '';
        }

        return WORKER_DESKTOP_LINK || '';
    }

    /**
     * Uses react-device-detect to return the detected app store link for the device OS
     */
    determineAppStoreLink(): string {
        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;
        const iOSCustomerLink = constants.APP_STORE_IOS_LINK;
        const androidCustomerLink = constants.APP_STORE_ANDROID_LINK;
        const iOSWorkerLink = constants.APP_STORE_IOS_WORKER_LINK;
        const androidWorkerLink = constants.APP_STORE_ANDROID_WORKER_LINK;

        const userType = this.userType;
        // Limited to worker for now - WDC-584
        if (!userType || userType !== "WORKER") {
            return androidCustomerLink;
        }

        if (isIOS) {
            return iOSWorkerLink;
        }

        return androidWorkerLink;
    }
}