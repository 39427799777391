import css from './unsubscribePage.module.scss';
import React, {useCallback, useEffect} from 'react';
import {IUnsubscribeStore} from "../../stores/UnsubscribeStore";
import {resources} from '../../resources/strings';
import {ConfigService} from "../../services/ConfigService";
import Helmet from 'react-helmet';
import UnsubscribeLayout from "../../components/UnsubscribeLayout";
import Interlacer from "../../components/Interlacer";
import {ClientTrackingService} from "../../services/ClientTrackingService";
import {ScreenRoutes} from "../../ActivePage";
import {combineClassnames} from "../../utils/combineClassnames";
import {Observer} from "mobx-react";
import Button from '@material-ui/core/Button';
import {createTheme, ThemeProvider} from "@material-ui/core";
import {StylesProvider} from "@material-ui/core/styles";
import {ClientTrackingNames} from "../../models/ClientTracking";

export type UnsubscribePageProps = {
    store: IUnsubscribeStore;
    pathId?: string;
}

function isRequestResolved(store: IUnsubscribeStore): boolean {
    return ['success', 'error'].includes(store.requestStatus);
}

function UnsubscribePage(props: UnsubscribePageProps) {
    const pathId = props.pathId ?? '';
    const {constants} = ConfigService.getInstance().values;
    const alignContent = constants.UNSUBSCRIBE_ALIGN_CONTENT || 'left';
    const clientTracking = ClientTrackingService.getInstance();
    const {email, userType} = props.store;

    useEffect(() => {
        clientTracking.trackRender(ClientTrackingNames.Unsubscribe);
    }, []);

    const handleUnsubscribeClicked = useCallback(async () => {
        const notificationId = props.store.notificationId || pathId;
        clientTracking.trackClick(ClientTrackingNames.UnsubscribeSubmit, {pathId: notificationId});

        if (!props.store.notificationId && pathId) {
            props.store.setNotificationId(pathId);
        }

        if (isRequestResolved(props.store)) {
            return;
        }

        const result = await props.store.sendUnsubscribeRequest();

        if (result === 'success') {
            clientTracking.trackClick(ClientTrackingNames.UnsubscribeSuccess, {pathId: notificationId});
            window.location.replace(
                `${ScreenRoutes.Unsubscribe}/${pathId}/success/?userType=${userType}`
            );
            return;
        }

        clientTracking.trackClick(ClientTrackingNames.UnsubscribeFail, {pathId: notificationId});
        window.location.replace(
            `${ScreenRoutes.Unsubscribe}/${pathId}/failed`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.store, pathId]);

    // TODO: detect if customer/worker
    // - from url param?
    // - can we call an endpoint to get this information?
    const appLink = props.store.appStoreLink;
    const desktopLink = props.store.desktopLink;

    // TODO: interlace regardless of how many paragraph elements there are
    const content = (
        <>
            <Helmet>
                <link rel="preload" as="image" href={constants.PARTNER_LOGO_URL}/>
            </Helmet>
            <UnsubscribeLayout
                header={
                    <>
                        <img
                            className={css.partner_logo}
                            src={constants.PARTNER_LOGO_URL}
                            alt={resources.unsubscribe.partnerLogoAltText}
                        />
                        <p className={css.heading}>
                            {resources.unsubscribe.manageYourEmailPreferences}
                        </p>
                    </>
                }
                paragraphs={(
                    <>
                        <Interlacer
                            template={resources.unsubscribe.paragraphs[0]}
                            data={[email]}
                            as="p"
                            className={css.body_text}
                        />
                        <Interlacer
                            template={resources.unsubscribe.paragraphs[1]}
                            data={[]}
                            as="p"
                            className={css.body_text}
                        />
                        <Interlacer
                            template={resources.unsubscribe.paragraphs[2]}
                            data={[
                                <a key={0} className={css.link_text} href={appLink} target="_blank"
                                   rel="noopener noreferrer">{resources.unsubscribe.appLinkText}</a>,
                                <a key={1} className={css.link_text} href={desktopLink} target="_blank"
                                   rel="noopener noreferrer">{resources.unsubscribe.desktopLinkText}</a>
                            ]}
                            as="p"
                            className={css.body_text}
                        />
                    </>
                )}
                sectionClassName={css.instruction_section}
                alignContent={alignContent}>
                <div className={css.button_row}>
                    <Observer>
                        {() => (
                            <Button
                                variant="contained"
                                color="primary"
                                className={combineClassnames(
                                    css.unsubscribe_button,
                                    props.store.requestStatus !== 'idle' ? (
                                        css.unsubscribe_button_disabled
                                    ) : null
                                )}
                                classes={{
                                    root: css.unsubscribe_button,
                                    label: css.unsubscribe_button_label
                                }}
                                disabled={props.store.requestStatus !== 'idle'}
                                onClick={handleUnsubscribeClicked}>
                                {resources.unsubscribe.unsubscribeButton}
                            </Button>
                        )}
                    </Observer>
                </div>
            </UnsubscribeLayout>
        </>
    );

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={createTheme({
                palette: {
                    primary: {
                        main: css.unsubscribe_button_color
                    }
                },
                typography: {
                    button: {
                        textTransform: 'none'
                    }
                }
            })}>
                {content}
            </ThemeProvider>
        </StylesProvider>
    );
}

export default UnsubscribePage;
