import { filter, isNil, map } from 'lodash-es';
import React, {CSSProperties} from 'react';

interface Props {
    /**
     * Template string to insert React nodes into. Must contain index style insertion points: "{0}".
     */
    template: string;   // TODO: add support for named insertion points
    /**
     * List of React nodes to insert, in order.
     */
    data: React.ReactNode[];
    /**
     * Optional callback to render the tokens differently.
     * @param tokens
     */
    renderTokens?: (tokens: React.ReactNode[]) => React.ReactElement<any, any>;
    /**
     * Optional prop to render the container for the inserted template as another DOM element or a React component.
     */
    as?:
        | React.ComponentType<{ children: React.ReactNode | React.ReactNode[] }>
        | keyof JSX.IntrinsicElements;
    /**
     * Flag to display the insertion point, ie. "{0}" when there is a missing data for that index.
     * Defaults to true
     */
    showMissingData?: boolean;
    /**
     * Optional class name to pass to the container
     */
    className?: string;
    /**
     * Optional styles to pass to the container
     */
    containerStyle?: CSSProperties;
}

const defaultDOMElement = 'span';
/**
 * Inserts any valid React nodes into a template string.
 */
const Interlacer = ({
                        template,
                        data,
                        renderTokens,
                        as = defaultDOMElement,
                        showMissingData = true,
                        className = undefined,
                        containerStyle = undefined
                    }: Props) => {
    if (!template?.length) {
        return null;
    }

    const tokens = filter(template.split(/({\d+})/g), (token) => token.length > 0);
    const mappedTokens: React.ReactNode[] = map(tokens, (token: string) => {
        const insertionPointTest: RegExpExecArray | null = /{(\d+)}/g.exec(token);

        if (insertionPointTest !== null && insertionPointTest.length > 1) {
            const captureGroupIndex = 1;
            const fallbackInsertion = showMissingData ? token : null;
            // @ts-ignore
            return data[insertionPointTest[captureGroupIndex]] ?? fallbackInsertion;
        }

        return token;
    });

    if (typeof renderTokens === 'function') {
        return renderTokens(mappedTokens);
    }

    const RenderAs = as;

    // default to render as a span
    // but could offer to render as any other component or map the tokens some how
    return (
        <RenderAs className={className} style={containerStyle}>
            {mappedTokens.map((token: React.ReactNode, index: number) => {
                // ReactChild / ReactText / ReactFragment / ReactPortal
                // require a key
                if (React.isValidElement(token)) {
                    // eslint-disable-next-line react/no-array-index-key
                    return React.cloneElement(token, { key: index });
                }

                // null / undefined
                if (isNil(token)) {
                    return null;
                }

                // rest should be primitive
                return token;
            })}
        </RenderAs>
    );
};

export default Interlacer;