import {ConfigService} from "../../../services/ConfigService";
import unsubscribeStyles from "../unsubscribePage.module.scss";
import successStyles from '../UnsubscribeSuccessPage/styles.module.scss';
import {resources} from "../../../resources/strings";
import React, {useEffect} from "react";
import Helmet from 'react-helmet';
import UnsubscribeLayout from "../../../components/UnsubscribeLayout";
import {ClientTrackingService} from "../../../services/ClientTrackingService";
import {ClientTrackingNames} from "../../../models/ClientTracking";

const UnsubscribeFailedPage = () => {
    const {constants} = ConfigService.getInstance().values;
    const alignContent = 'center';
    const clientTracking = ClientTrackingService.getInstance();

    useEffect(() => {
        clientTracking.trackRender(ClientTrackingNames.UnsubscribeFail);
    }, []);

    return (
        <>
            <Helmet>
                <link rel="preload" as="image" href={constants.PARTNER_LOGO_URL}/>
            </Helmet>
            <UnsubscribeLayout
                header={(
                    <img
                        className={unsubscribeStyles.partner_logo}
                        src={constants.PARTNER_LOGO_URL}
                        alt={resources.unsubscribe.partnerLogoAltText}
                    />
                )}
                paragraphs={
                    <p className={successStyles.subtitle_text}>{resources.unsubscribe.failedToUnsubscribe}</p>
                }
                alignContent={alignContent}
            />
        </>
    );
};

export default UnsubscribeFailedPage;
