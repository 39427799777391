import {ConfigService} from "../../../services/ConfigService";
import css from './styles.module.scss';
import {resources} from "../../../resources/strings";
import React, {useEffect} from "react";
import Helmet from 'react-helmet';
import UnsubscribeLayout from "../../../components/UnsubscribeLayout";
import Interlacer from "../../../components/Interlacer";
import {IUnsubscribeStore} from "../../../stores/UnsubscribeStore";
import {ClientTrackingService} from "../../../services/ClientTrackingService";
import {ClientTrackingNames} from "../../../models/ClientTracking";

export type UnsubscribeSuccessPageProps = {
    store: IUnsubscribeStore;
}

const UnsubscribeSuccessPage = (props: UnsubscribeSuccessPageProps) => {
    const {constants} = ConfigService.getInstance().values;
    const alignContent = 'center';
    const {appStoreLink, desktopLink} = props.store;
    const clientTracking = ClientTrackingService.getInstance();

    useEffect(() => {
        clientTracking.trackRender(ClientTrackingNames.UnsubscribeSuccess);
    }, []);

    return (
        <>
            <Helmet>
                <link rel="preload" as="image" href={constants.PARTNER_LOGO_URL}/>
                <link rel="preload" as="image" href={constants.PARTNER_LOGO_LARGE_URL}/>
            </Helmet>
            <UnsubscribeLayout
                header={
                    <picture className={css.partner_logo_override}>
                        <source
                            media="(max-width: 499px)"
                            srcSet={constants.PARTNER_LOGO_URL}
                        />
                        <source
                            media="(min-width: 500px) and (min-height: 768px)"
                            srcSet={constants.PARTNER_LOGO_LARGE_URL}
                        />
                        <img
                            className={css.partner_logo_override}
                            src={constants.PARTNER_LOGO_URL}
                            alt={resources.unsubscribe.partnerLogoAltText}
                        />
                    </picture>
                }
                paragraphs={(
                    <>
                        <div className={css.inline_flex_row}>
                            <span className={`material-icons ${css.success_tick}`}>check_circle</span>
                            <p className={css.emphasis_text}>{resources.unsubscribe.successfullyUnsubscribed}</p>
                        </div>
                        <p className={css.subtitle_text}>
                            <Interlacer
                                template={resources.unsubscribe.viewNotificationPreferencesLinks}
                                data={[
                                    <a key={0} href={appStoreLink} target="_blank"
                                       rel="noopener noreferrer">{resources.unsubscribe.appLinkText}</a>,
                                    <a key={1} href={desktopLink} target="_blank"
                                       rel="noopener noreferrer">{resources.unsubscribe.desktopLinkText}</a>
                                ]}
                            />
                        </p>
                    </>
                )}
                alignContent={alignContent}
                sectionClassName={css.section_override}
                className={css.main_override}
            />
        </>
    );
};

export default UnsubscribeSuccessPage;
