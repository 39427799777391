import "reflect-metadata";
import * as React from 'react';
import {useRef} from 'react';
import ActivePage from "./ActivePage";
import './App.scss';

import {CustomerRegistrationStore} from './stores/CustomerRegistrationStore';
import {ConfigProvider} from '@swipejobs/frontend-config-provider';
import {HttpService} from '@swipejobs/frontend-comms-services';
import TokenService from './services/TokenService';
import {ConfigService} from './services/ConfigService';
import {UnsubscribeStore} from "./stores/UnsubscribeStore";
import {EmailService} from "./services/EmailService";

function App() {

    /******* Initial setup for now - do not touch or it will stop working *********/

    const configProvider = ConfigProvider.getInstance();
    const environmentConfig = ConfigService.getInstance().values;

    if (!environmentConfig) {
        console.error('Error, need to set the env in package.json');
    }

    configProvider.manualOverride(environmentConfig.constants, environmentConfig.features, environmentConfig.strings);

    const apiUrl = environmentConfig.constants.apiUrl as string;

    const apiConfigurationService = {
        apiUrl,
        buildApiUrl: (url: string) => {
            const normalisedApiUrl = apiUrl.endsWith('/') ? apiUrl.slice(0, apiUrl.length - 1) : apiUrl;
            const normalisedUrl = url.startsWith('/') ? url : `/${url}`;

            // should be "API_URL" + "/URL"
            return normalisedApiUrl + normalisedUrl;
        }
    };

    const httpService = new HttpService();

    const tokenService = new TokenService(apiConfigurationService, httpService);
    const store = new CustomerRegistrationStore(apiConfigurationService, httpService, tokenService);
    const emailService = new EmailService(
        apiConfigurationService,
        httpService
    );
    const unsubscribeStore = useRef(new UnsubscribeStore(emailService, window.location.search));

    // load the guest token as soon as the app loads - we wanna land the user on the screen asap
    React.useEffect(() => {

        const initGuestToken = async () => {
            await tokenService.initGuestToken();
        };

        initGuestToken();
    }, []);

    /******* END *********/

    return (
        <div className='App'>
            <ActivePage
                store={store}
                unsubscribeStore={unsubscribeStore.current}
            />
        </div>
    );
}

export default App;
